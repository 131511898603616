<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">质量零缺陷分析系统（FMEA管理软件）</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        IATF16949汽车质量管理体系中的制造过程审核，是IATF16949每年内部审核的一部分。
      </p>
      <p class="SmallP" style="text-indent: 2em">
        FMEA-Assistant软件是景翔根据20多年FMEA辅导的经验，针对企业FMEA分析及资料管理的真正需求而开发，是确保企业落实产品设计开发零缺陷的有效手段！提高研发人员，工艺人员FMEA分析的有效性和效率，是确保FEMA资料完整及高效搜索的有力帮手！
      </p>
      <br />
      <br />
      <p class="PTitle">我们的优势</p>
      <p class="SmallP" style="text-indent: 2em">
        辅导国内第一家汽车零部件企业通过汽车质量管理体系的咨询公司，深耕汽车行业20多年。
        我司成立于1997年，专业从事国际标准管理体系咨询和培训业务，20多年来服务的客户中不乏世界500强企业、大型港台企业、著名的上市公司和服务公司等，客户类型覆盖广泛，目前已成为省内客户及整合一体化体系最多的咨询公司。
      </p>
      <br />
      <br />
      <p class="PTitle">管理体系内部审核步骤：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/60.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle">体系审核、制造过程审核、产品审核的区别:</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/64.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <p class="PTitle">体系审核和制造过程审核文件的区别:</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/66.png"
        class="SonImgW"
      />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/67.png"
        class="SonImgW"
      />
      <br />
      <br />
      <p class="PTitle LeftBor">IATF16949制造过程审核（VDA6.3）重点：</p>
      <div class="PList" style="margin-top: 10px">
        <p>1. 顾客特殊要求的识别与落实。</p>
        <p>2. 审核“过程方法”的应用。</p>
        <p>3. 对VDA6.3标准的理解程度。</p>
        <p>4. 过程审核的策划。</p>
        <p>5. 过程审核检查表的编制。</p>
        <p>6. 现场审核问题发现。</p>
        <p>7. 审核评价。</p>
        <p>8. 审核问题整改。</p>
        <p>9. 审核总结及分析。</p>
        <p>12. .....</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>